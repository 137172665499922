@import "~normalize.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  font-family: Inter;
  position: relative;

  background: #ffffff;
}
.loading-progress {
  top: 56px;
}
button {
  cursor: pointer;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

// Step Card Styles
.mat-button-toggle-group {
  width: 100%;
  gap: 5px;
  border: none;
}
.mat-button-toggle {
  border-radius: 4px;
  border: 1px solid gray !important;
  height: 80px;
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border: none;
}

// Button toggle styles
.mat-button-toggle-group {
  .mat-button-toggle-checked {
    background-color: transparent;
    &.blue {
      border-color: #0058a3 !important;
      color: #0058a3;
    }
    &.red {
      border-color: #a01d28 !important;
      color: #a01d28 !important;
    }
    &.green {
      border-color: #00a593 !important;
      color: #00a593 !important;
    }
  }
}

// Buttons
.button-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;
}
.button-row.noback {
  justify-content: end;
}
.back-btn,
.next-btn {
  border-radius: 8px;
  background: #0058a3;
  display: flex;
  color: white;
  padding: 12px 20px;
  align-items: center;
  border: 1px;
  gap: 8px;
  cursor: pointer;
}
.back-btn {
  background-color: white;
  color: #0058a3;
}

.next-btn:disabled {
  background-color: rgba(19, 1, 1, 0.3) !important;
  color: rgba(255, 255, 255, 0.3) !important;
  border-color: rgba(195, 195, 195, 0.3) !important;
}

.text-area {
  width: 100%;
}
